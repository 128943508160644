import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/requests/list',config);
}
const show = async (id,config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/housing/requests/detail/${id}`,config);
}
const getAllAdmin = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/requests',config);
}
const showAdmin = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/housing/requests/${id}`);
}
const downloadFile = (uuid) => {
    return Api.get('/housing/requests/download/' + uuid, { responseType: 'arraybuffer' });
}
const changeStatusRequest = async (id,formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post(`/housing/requests/${id}/change-status`,formData);
}
export default {
    getAll,
    show,
    getAllAdmin,
    showAdmin,
    downloadFile,
    changeStatusRequest
}
